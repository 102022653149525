<template>
  <div class="talk-home" v-wechat-title="titleMeta">
    <div class="header">
      <div class="header-placeholder">
        <div class="header-inner">
          <img class="logo" :src="logo" alt="logo" />
          <a class="tabnav topic" @click="clickHeaderNav('topic')">今日话题</a>
          <a class="tabnav follow current" @click="clickHeaderNav('follow')">关注动态</a>
        </div>

        <UserBar></UserBar>
      </div>
    </div>
    <div class="talk-list">
      <div class="left-type">
        <ol class="type-list" ref="type-list">
          <li
            class="type-item icon-tujian"
            :class="{ cur: currentType == item.type }"
            v-for="(item, index) in dataTypes"
            :key="index"
            @click="initFlowData(item)"
          >
            <i :class="item.icon"></i>{{ item.name }}
          </li>
        </ol>
      </div>
      <div class="middle-list" ref="middle-list">
        <!-- 信息流 -->
        <div class="module-list">
          <div class="ml-loading" v-show="listData.length == 0 && !loading">
            <div class="ml-tips">
              <img src="../../assets/image/nocomment.png" alt="" />
              <div class="ml-text">暂无关注,快来关注吧~</div>
            </div>
          </div>
          <ul class="list" v-if="listData.length">
            <li class="list-item" v-for="(item, index) in listData" :key="index">
              <!-- 一条最近新闻 -->
             <template v-if="item.recent_news.length === 1">
              <div class="tag" @click="gotopicpage(item)">
                <div class="tag_title">#{{item.talk_name}}#</div>
                <div class="tag_date">{{formatTimestamp(item.update_time)}} 更新</div>
                <span :class="item.op_type === '1' ? 'tag_follow' : 'tag_followed'" @click.stop="listfollow(item)">{{ item.op_type === '1' ? '+ 关注' : '取消关注' }}</span>
              </div>
              <div class="content" @click="goListpage(item.recent_news[0], item)">
                <div class="list-info-box" :style="!item.recent_news[0].imgs[0] ? 'width: 100%': ''">
                  <div class="title" >{{item.recent_news[0].section_name}}</div>
                  <div class="desc">{{item.recent_news[0].news_title}}</div>
                </div>
                <div class="list-img-box" v-if="item.recent_news[0].imgs[0]">
                  <a class="img-warp"><img :src="item.recent_news[0].imgs[0]" alt=""/></a>
                </div>
              </div>
            </template>
            <!-- 超过一条最近新闻 -->
            <template v-else-if="item.recent_news.length > 1">
              <div class="tag" @click="gotopicpage(item)">
                <span class="tag_title">#{{item.talk_name}}#</span>
                <span class="tag_date">{{formatTimestamp(item.update_time)}} 更新</span>
                <span :class="item.op_type === '1' ? 'tag_follow' : 'tag_followed'" @click.stop="listfollow(item)">{{ item.op_type === '1' ? '+ 关注' : '取消关注' }}</span>
              </div>
              <div class="content">
                <div class="list-info-box" :style="!item.recent_news[0].imgs[0] ? 'width: 100%': ''">
                  <li v-for="(t , i) in item.recent_news.slice(0,3)" :key="i" @click="goListpage(t,item)">
                    <span class="event_name">{{t.section_name}}</span>
                    <span class="event_title">{{t.news_title}}</span>
                  </li>
                </div>
                <div class="list-img-box" v-if="item.recent_news[0].imgs[0]">
                  <a class="img-warp" ><img :src="item.recent_news[0].imgs[0]" alt=""/></a>
                </div>
              </div>
            </template>
            <!-- 无内容 -->
            <template v-else>
              <div class="tag" @click="gotopicpage(item)">
                <span class="tag_title">#{{item.talk_name}}#</span>
                <span class="tag_date">{{formatTimestamp(item.update_time)}} 更新</span>
                <span :class="item.op_type === '1' ? 'tag_follow' : 'tag_followed'"  @click.stop="listfollow(item)">{{ item.op_type === '1' ? '+ 关注' : '取消关注' }}</span>
              </div>
            </template>
             </li>
          </ul>

          <loadIcon v-if="loading" />
          <div v-if="noMoreData && listData.length != 0" class="isnodata">亲，已经到底了</div>
        </div>
      </div>
      <div class="right-module">
        <!-- 热门话题 -->
        <div class="module-topic common-module" ref="module-topic">
          <div id="hot-flow-module">
            <p class="module-title hot-icon">热门话题</p>
            <ol class="hot-topic-list">
              <li class="hot-item" v-for="(m, n) in talkData" :key="n" :pdata="m.pdata" @click="goHuaTi(m)">
                <a>
                  <i class="serial-num" :class="`no${n + 1}`">{{ n + 1 }}</i>
                  #{{ m.zt_name }}#
                </a>
                <span class="icon hot" v-if="n <= 2"></span>
              </li>
            </ol>
          </div>
        </div>
        <!-- 兴趣 -->
        <div class="module-ins common-module" ref="module-ins">
          <div id="hot-flow-module">
            <p class="module-title ins-icon">可能感兴趣</p>
            <ol class="ins-list">
              <li class="ins-item" v-for="(m, n) in recData" :key="n">
                <a @click="goIns(m)"> #{{ m.talk_name }}# </a>
                <div :class="m.op_type === '1' ? 'follow' : 'followed'" @click="followtopic(m)">
                  {{ m.op_type === '1' ? '+ 关注' : '已关注' }}
                </div>
              </li>
              <!-- <li class="ins-item">
                <a>
                  #哈马斯与以色列达成停火协议#
                </a>
                <div class="follow" @click="followtopic()">+ 关注</div>
              </li>
              <li class="ins-item">
                <a>
                  #广州发现1例核酸疑似阳性人员#
                </a>
                <div class="followed" @click="followtopic()">已关注</div>
              </li>-->
            </ol>
          </div>
        </div>
        <!-- 关于我们 -->
        <div class="module-about common-module">
          <div class="instructions">
            <p>
              <span class="mr15"><a href="/talk/about?type=us" target="_blank">联系我们</a></span>
              <!-- <a href="/talk/about?type=us" target="_blank">关于我们</a> -->
            </p>
            <p>互联网新网信息服务许可证：31120170005</p>
            <p>24小时违法和不良信息举报电话：021-60897139</p>
            <p>网上有害信息举报专区</p>
            <p>tantao.com All Rright Reserve 版权所有</p>
          </div>
        </div>
      </div>
      <div class="tool-bar" @click="backScrollTop"></div>
    </div>

    <!-- 关注 -->
    <section class="follow-dialog">
      <el-dialog :visible.sync="followDialogVisible">
        <p class="text">{{ followDialogTitle }}</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="followDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="followconfirm">确定</el-button>
        </span>
      </el-dialog>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getHotData } from '@/api/home.js';
import { recomendConcern, getRecentNews, userConcern } from '@/api/axios.request';
import {
  getOffset,
  getScrollPosition,
  addClass,
  removeClass,
  scrollTo,
  // isHresolution,
} from '@/util/index.js';
import { formatEmojiBr } from '@/util/methods.js';
import loadIcon from '@/components/loadIcon/index.vue';
import UserBar from '@/components/userBar/userBar.vue';
import VueCookies from 'vue-cookies';
import { activeLog, baiduLog } from '@/util/log.js';
import logoPath from '../../assets/image/logo-2.png';
// import logoSmPath from '../../assets/image/logo-s.png';
let FormlY = '';
export default {
  name: 'Concern',
  components: {
    loadIcon,
    UserBar,
  },
  data() {
    return {
      titleMeta: '动态_探讨',
      pagetype: 'concern',
      newstype: 'tantao',
      timer: '',
      loadlock: false, // 加载信息数据的lock
      loading: true, // 加载
      currnet: 'Concern',
      dataTypes: [],
      noMoreData: false,
      currentType: 'tuijian',
      currentPage: 1,
      currentIndex: 0,
      listData: [],
      talkData: [], // 热门话题
      recData: [], // 推荐关注
      logo: logoPath,
      followDialogVisible: false, // 关注提示框
      followData: '', //关注数据
      followDialogTitle: '', //关注提示框标题
    };
  },
  created() {
    this.initCateData();
    // const isH = isHresolution();
    // if (!isH) this.logo = logoSmPath;
  },
  beforeRouteEnter(to, from, next) {
    FormlY = from.fullPath;
    next();
  },
  mounted() {
    this.scrollLoadData();
    this.reportLog();
  },
  computed: {
    ...mapState(['token', 'userInfo', 'accountDialog', 'hasUseRefer', 'userInfo']),
  },
  watch: {
    accountDialog: {
      handler(curVal) {
        if (!curVal) {
          location.reload();
        }
      },
    },
  },
  methods: {
    formatEmojiBr,
    // 日志上报
    reportLog() {
      baiduLog();
      let pdata = VueCookies.get('TJ_DATA');
      VueCookies.remove('TJ_DATA');
      if (pdata) {
        pdata = pdata.split('|');
      } else {
        pdata = [];
      }

      let vfrom = '';
      if (!this.hasUseRefer && document.referrer) {
        vfrom = document.referrer;
        this.$store.commit('SET_HASUSEERFER', true);
      } else {
        if (!FormlY || FormlY === '/') vfrom = 'null';
        else vfrom = `${location.protocol}//${location.host}${FormlY}`;
      }

      activeLog({
        accid: this.userInfo ? this.userInfo.id : -1,
        btype: pdata[0] || 'null',
        subtype: pdata[1] || 'null',
        pageType: this.pagetype,
        idx: pdata[2] || 'null',
        newstype: this.newstype,
        pgnum: pdata[4] || 'null',
        from: vfrom,
        to: window.location.href,
        fr_url: vfrom,
      });
    },
    // 重置
    reset() {
      this.loadlock = false;
      this.currentPage = 1;
      this.currentIndex = 0;
      this.listData = [];
      this.currentType = 'tuijian';
    },
    // 导航跳转
    clickHeaderNav(name) {
      if (name == 'topic') this.$router.push({ path: '/' });
    },
    // 初始化话题类表类型
    initCateData() {
      this.dataTypes = [{ icon: 'icon-tuijian', id: '001', name: '推荐', type: 'tuijian' }];
      this.initFlowData();
      getHotData().then((response) => {
        if (!response) return;
        const { hot_talk } = response;
        hot_talk.forEach((item, idx) => {
          item.pdata = `index|hot_talk|${idx}|0`;
        });
        this.talkData = hot_talk;
        this.moduleScrollFixed();
      });
      // 推荐关注
      recomendConcern().then((res) => {
        if (!res.data) return;
        const rec_data = res.data.result;
        rec_data.forEach((item) => {
          item.op_type = '1';
        });
        this.recData = rec_data;
      });
    },
    // 初始化信息流数据
    initFlowData(em) {
      scrollTo(0, 400, () => {});
      this.reset();
      if (em) this.currentType = em.type;
      this.loading = true;
      this.loadlock = true;
      let param = {
        page_no: this.currentPage,
      };
      getRecentNews(param)
        .then((response) => {
          if (!response.data || !response.data.result || !response.data.result.length) {
            this.loading = false;
            this.noMoreData = true;
            return;
          }
          // 列表数据
          const res = response.data.result;
          const newres = [];
          let index = 0;
          res.forEach((item) => {
            item.pdata = `index|${this.currentType}|${index}|0|${this.currentPage}`;
            item.op_type = '0';
            newres.push(item);
            ++index;
          });
          // 加载数据
          this.listData = newres;
          this.loading = false;
          this.loadlock = false;
        })
        .catch(() => {
          this.loading = false;
          this.noMoreData = true;
        });
    },
    // 加载信息流数据
    loadFlowData() {
      this.currentPage += 1;
      this.loading = true;
      let param = {
        page_no: this.currentPage,
      };
      getRecentNews(param)
        .then((response) => {
          if (!response.data || !response.data.result || !response.data.result.length) {
            this.loading = false;
            this.noMoreData = true;
            return;
          }
          response.data.result.forEach((item, idx) => {
            item.pdata = `index|${this.currentType}|${idx}|0|${this.currentType}`;
            item.op_type = '0';
            this.listData.push(item);
          });
          this.loadlock = false;
          this.loading = false;
          this.noMoreData = false;
        })
        .catch(() => {
          this.loading = false;
          this.noMoreData = true;
        });
    },
    // 滚动监听加载数据
    scrollLoadData() {
      window.addEventListener('scroll', this.handleFun);
    },
    // 滚动监听的回调函数
    handleFun() {
      const scope = this;
      let $middleList = this.$refs['middle-list'];
      if (!$middleList) return;
      let h = $middleList.clientHeight;
      let offsetTop = getOffset($middleList).top;
      let scrollTop = getScrollPosition().y;
      let screenHeight = document.documentElement.clientHeight;

      if (scope.loadlock) return;
      if (!$middleList) $middleList = this.$refs['middle-list'];
      if (!$middleList) return;
      h = $middleList.clientHeight;
      offsetTop = getOffset($middleList).top;
      screenHeight = document.documentElement.clientHeight;
      scrollTop = getScrollPosition().y;
      if (offsetTop + h <= screenHeight + scrollTop + 300) {
        scope.loadlock = true;
        scope.loadFlowData();
      }
    },
    // 左、右侧滚动悬浮
    moduleScrollFixed() {
      this.$nextTick(() => {
        let $moduleTopic = this.$refs['module-topic'];
        let $typeList = this.$refs['type-list'];
        const hotFlowModule = document.getElementById('hot-flow-module');
        let scrollTop = getScrollPosition().y;
        let offsetTop = getOffset($moduleTopic).top;
        setTimeout(() => {
          const rh = $moduleTopic.clientHeight;
          window.addEventListener('scroll', function(e) {
            e.preventDefault();
            scrollTop = getScrollPosition().y;
            if (scrollTop >= rh + offsetTop) {
              addClass(hotFlowModule, 'hot-flow-fixed');
            } else {
              removeClass(hotFlowModule, 'hot-flow-fixed');
            }

            if (scrollTop >= 20) {
              addClass($typeList, 'nav-flow-fixed');
            } else {
              removeClass($typeList, 'nav-flow-fixed');
            }
          });
        }, 1000);
      });
    },
    // 返回顶部
    backScrollTop() {
      scrollTo(0, 400);
    },
    // 跳转话题页面
    gotopicpage(item) {
      // if (item.pdata) VueCookies.set('TJ_DATA', item.pdata);
      // 新页面打开
      let routeUrl = this.$router.resolve({
        path: `/talk/${item.talk_id}/`,
      });
      window.open(routeUrl.href, '_blank');
      // this.$router.push({ path: `/talk/${item.id}` });
    },
    goListpage(t,row) {
      let uk = t.url.split('tantao.com')[1];
      let routeUrl = '';
      if (!uk) {
        routeUrl = this.$router.resolve({
          path: `/talk/${row.talk_id}/`,
          query: {'column': t.section_name}
        });
      } else {
       routeUrl = this.$router.resolve({
        path: uk
       });
      }
      window.open(routeUrl.href, '_blank');
    },
    // 跳转话题页
    goHuaTi(item) {
      if (!item.url) return;
      if (item.pdata) VueCookies.set('TJ_DATA', item.pdata);
      // 新页面打开
      let routeUrl = this.$router.resolve({
        path: `${item.url}`,
      });
      window.open(routeUrl.href, '_blank');
      // this.$router.push({ path: item.url });
    },
    goIns(item) {
      let routeUrl = this.$router.resolve({
        path: `/talk/${item.talk_id}/`,
      });
      window.open(routeUrl.href, '_blank');
    },
    // 信息流关注
    listfollow(row) {
      let _this = this;
      if (_this.token) {
        this.followData = row;
        if (row.op_type === '1') {
          this.followDialogTitle = '确定关注该话题吗？';
        } else if (row.op_type === '0') {
          this.followDialogTitle = '确认是否取消关注？';
        }
        this.followDialogVisible = true;
      } else {
        _this.$store.commit('SET_ACCOUNTDIALOG', true);
      }
    },
    // 推荐话题关注
    followtopic(row) {
      let _this = this;
      if (_this.token) {
        this.followData = row;
        if (row.op_type === '1') {
          this.followDialogTitle = '确定关注该话题吗？';
        } else if (row.op_type === '0') {
          this.followDialogTitle = '确认是否取消关注？';
        }
        this.followDialogVisible = true;
      } else {
        _this.$store.commit('SET_ACCOUNTDIALOG', true);
      }
    },
    followconfirm() {
      let row = this.followData;
      let param = {
        talk_id: row.talk_id,
        op_type: row.op_type,
      };
      userConcern(param)
        .then(() => {
          if (row.op_type === '0') {
            row.op_type = '1';
          } else if (row.op_type === '1') {
            row.op_type = '0';
          }
          this.followDialogVisible = false;
        })
        .catch(() => {
          this.followDialogVisible = false;
        });
    },
    formatTimestamp(d) {
      let t = 0;
      t =  parseInt(d);
      
      if (!t) return '今天';

      function addZero(n) {
        return n < 10 ? '0' + n : n;
      }
      var date = new Date(t * 1000),
        month = date.getMonth() + 1,
        day = date.getDate();

      var c = new Date().getTime(),
        diff = Number(c - date.getTime()), // 相差ms
        dy = 365 * 24 * 60 * 60 * 1000, // 1年
        dd = 24 * 60 * 60 * 1000; // 1分钟
      if (diff < dy) {
        var days = diff / dd - 1; // 相差天数
        if (days > 2) {
          return addZero(month) + '-' + addZero(day);
        } else if (days > 1) {
          return '2天前';
        } else if (days > 0) {
          return '1天前';
        } else {
          return '今天';
        }
      } else {
        return addZero(month) + '-' + addZero(day);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleFun);
  },
};
</script>

<style lang="scss" scoped>
@import './concern.scss';
</style>
<style lang="scss">
.talk-home {
  .talk-list {
    .middle-list {
      .module-list {
        .list-item {
          .usercomment {
            img {
              width: 16px;
              height: 16px;
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
}
</style>
