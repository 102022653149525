import { jsonp } from 'vue-jsonp';
let Host = 'www.tantao.com';
if (/testwww|localhost|127\.0\.0\.1/.test(location.hostname)) Host = 'testwww.tantao.com';
/**
 * 获取首页话题列表分类
 */
export function getTopicTypes() {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          type: 'tuijian',
          name: '推荐',
          id: '001',
        },
        {
          type: 'yule',
          name: '娱乐',
          id: '002,',
        },
        {
          type: 'shehui',
          name: '社会',
          id: '003',
        },
        {
          type: 'keji',
          name: '科技',
          id: '004',
        },
        {
          type: 'qinggan',
          name: '感情',
          id: '005',
        }
      ],
    });
  });
}
/**
 * 获取首页话题列表数据
 */
export function getTopicList(type, pumg) {
  return jsonp(`//${Host}/api/zt/index-v2/${type}/${pumg}.json`, {
    callbackName: `${type}${pumg}`,
  });
}
/**
 * 获取首页右侧接口内容
 */
export function getHotData() {
  return jsonp(`//${Host}/api/zt/index/right-side/hot-talk.json`, {
    callbackName: 'hotTalk',
  });
}
/**
 *获取人工编辑轮播数据
 * @export
 * @returns
 */
export function getLunboData() {
  return jsonp(`//${Host}/api/zt/index-slider/talk-slider.json`, {
    callbackName: 'talkSlider',
  });
}